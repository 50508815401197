import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'reactstrap';
import { useSelector } from 'react-redux';
import SkillInfo from './SkillInfo';
import SkillForm from './SkillForm';

function SkillTreeModal(props) {
  const [edit, setEdit] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const { isOpen, toggle, data } = props;
  const auth = useSelector((state) => state.auth);



  useEffect(() => {
    setIsAuthenticated(auth.isAuthenticated);
  }, [auth]);

  return (
    <Modal className="skill-tree-modal" isOpen={isOpen} toggle={() => toggle(false)} size="xl">
      <div className="modal-header no-border-header">
        <button className="close" type="button" onClick={() => toggle(false)}>
          <i className="nc-icon nc-simple-remove" />
        </button>
        {isAuthenticated &&
          (edit ? (
            <button className="settings" type="button" onClick={() => setEdit(false)}>
              <i className="nc-icon nc-minimal-left" />
            </button>
          ) : (
            <button className="settings" type="button" onClick={() => setEdit(true)}>
              <i className="nc-icon nc-settings-gear-65" />
            </button>
          ))}
        <h2 className="title mb-0">{data.name}</h2>
      </div>
      <div className="modal-body">
        {edit ? <SkillForm data={data} /> : <SkillInfo data={data} />}
      </div>
      <div className="modal-footer">
        <Button className="btn-link" color="primary" type="button" onClick={() => toggle(false)}>
          Okay
        </Button>
      </div>
    </Modal>
  );
}
export default SkillTreeModal;
