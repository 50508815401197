import React from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import TagsInput from 'react-tagsinput';
import LoadingMask from '../../common/LoadingMask';

function SkillInfo(props = {}) {
  const { data } = props;
  const {
    description = '',
    difficulty = '',
    musclesUsed = [],
    relatedInjuries = [],
    otherNames = [],
    videoURL = '',
    category = '',
  } = data;
  const _difficulty = difficulty ? [difficulty] : [];
  const _category = category ? [category] : [];
  const [vTabs, setVTabs] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const navLinkClass = (index) => `skill-info-link ${vTabs === index ? 'active' : ''}`;
  return (
    <Row className="skill-info-section">
      <Col xl="6" lg="12">
        <LoadingMask loading={loading}>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item ml-auto mr-auto mb-2 rounded"
              title="modal-video"
              width="560"
              height="315"
              src={videoURL}
              onLoad={() => setLoading(false)}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </LoadingMask>
      </Col>

      <Col xl="6" lg="12" className=" skill-info my-auto pt-4 ">
        <Row>
          <Col md="5" sm="6" xs="6" className="my-auto">
            <div className="nav-tabs-navigation">
              <div className="nav-tabs-wrapper">
                <Nav className="flex-column nav-stacked" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={navLinkClass(1)}
                      onClick={() => {
                        setVTabs(1);
                      }}
                    >
                      <span className="link-inner  small rounded-pill">Info</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={navLinkClass(2)}
                      onClick={() => {
                        setVTabs(2);
                      }}
                    >
                      <span className="link-inner  small rounded-pill">Description</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={navLinkClass(3)}
                      onClick={() => {
                        setVTabs(3);
                      }}
                    >
                      <span className="link-inner  small rounded-pill">Muscles Used</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={navLinkClass(4)}
                      onClick={() => {
                        setVTabs(4);
                      }}
                    >
                      <span className="link-inner  small rounded-pill">Related Injuries</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
          </Col>
          <Col md="7" sm="6" xs="6" className="pt-4">
            {/* Tab panes */}
            <TabContent activeTab={`vTabs${vTabs}`}>
              <TabPane tabId="vTabs1">
                <Row>
                  <Col>
                    <h6>Category</h6>
                    <div id="category-input">
                      <TagsInput
                        onlyUnique
                        addOnBlur
                        addOnPaste
                        tagProps={{
                          className: 'react-tagsinput-tag badge-info disabled',
                        }}
                        disabled
                        inputProps={{ placeholder: '' }}
                        value={_category}
                      />
                    </div>
                  </Col>
                  <Col>
                    <h6>Difficulty</h6>
                    <div id="difficulty-input">
                      <TagsInput
                        onlyUnique
                        addOnBlur
                        addOnPaste
                        tagProps={{
                          className: 'react-tagsinput-tag badge-info disabled',
                        }}
                        disabled
                        inputProps={{ placeholder: '' }}
                        value={_difficulty}
                      />
                    </div>
                  </Col>
                </Row>
                <h6>Other names</h6>
                <div id="other-names-input">
                  <TagsInput
                    placeholder="Add an alias"
                    onlyUnique
                    addOnBlur
                    addOnPaste
                    tagProps={{
                      className: 'react-tagsinput-tag badge-warning disabled',
                    }}
                    inputProps={{ placeholder: '' }}
                    value={otherNames}
                    disabled
                  />
                </div>
              </TabPane>
              <TabPane tabId="vTabs2">
                <h6>Description</h6>
                <p>{description}</p>
              </TabPane>
              <TabPane tabId="vTabs3">
                <h6>Muscles Used</h6>
                <div id="musclesUsed-input">
                  <TagsInput
                    onlyUnique
                    addOnBlur
                    addOnPaste
                    tagProps={{
                      className: 'react-tagsinput-tag badge-success disabled',
                    }}
                    inputProps={{ placeholder: '' }}
                    value={musclesUsed}
                    disabled
                  />
                </div>
              </TabPane>
              <TabPane tabId="vTabs4">
                <h6>Related Injuries</h6>
                <div id="related-injuries-input">
                  <TagsInput
                    onlyUnique
                    addOnBlur
                    addOnPaste
                    tagProps={{
                      className: 'react-tagsinput-tag badge-danger disabled',
                    }}
                    inputProps={{ placeholder: '' }}
                    value={relatedInjuries}
                    disabled
                  />
                </div>
              </TabPane>
              <TabPane tabId="vTabs5">
                <p>
                  It’s one continuous form where hardware and software function in perfect unison,
                  creating a new generation of phone that's better by any measure.
                </p>
                <p>
                  Larger, yet dramatically thinner. More powerful, but remarkably power efficient.
                  With a smooth metal surface that seamlessly meets the new Retina HD display.
                </p>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SkillInfo;
