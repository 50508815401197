import axios from 'axios';
import { createMessage, returnErrors } from './messages';
import { tokenConfig } from './auth';

import { GET_SKILLS, GET_SKILL, DELETE_SKILL, ADD_SKILL } from './types';

// GET SKILLS
export const getSkills = () => (dispatch, getState) => {
  axios
    .get('/api/skills/', tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SKILLS,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// GET SKILL
export const getSkill = (id) => (dispatch, getState) => {
  axios
    .get(`/api/skills/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SKILL,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// DELETE SKILL
export const deleteSkill = (id) => (dispatch, getState) => {
  axios
    .delete(`/api/skills/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteSkill: 'Skill Deleted' }));
      dispatch({
        type: DELETE_SKILL,
        payload: id,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// ADD SKILL
export const addSkill = (skill) => (dispatch, getState) => {
  const payload = JSON.stringify(skill);
  if (skill.id) {
    axios
      .put(`/api/skills/${skill.id}/`, payload, tokenConfig(getState))
      .then((res) => {
        dispatch(createMessage({ updateSkill: 'Skill Updated' }));
        dispatch({
          type: ADD_SKILL,
          payload: res.data,
        });
      })
      .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
    return;
  }
  axios
    .post('/api/skills/', payload, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addSkill: 'Skill Added' }));
      dispatch({
        type: ADD_SKILL,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};
