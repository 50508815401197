import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import store from './store';

// styles
import './assets/css/bootstrap.min.css';
import './assets/scss/paper-kit.scss';
import './assets/scss/paper-kit-overrides.scss';

import './assets/scss/custom.scss';
import './assets/demo/demo.css';
import './assets/demo/react-demo.css';

import Dashboard from './components/leads/Dashboard';
import SkillTree from './views/examples/SkillTree';
import Alerts from './components/layout/Alerts';
import Login from './components/accounts/Login';
import Register from './components/accounts/Register';
import PrivateRoute from './components/common/PrivateRoute';

import { loadUser } from './actions/auth';
import Index from './views/Index';

// For Development
import NucleoIcons from './views/NucleoIcons';
import Sections from './views/Sections';
import Presentation from './views/Presentation';
import AboutUs from './views/examples/AboutUs';
import AddProduct from './views/examples/AddProduct';
import BlogPost from './views/examples/BlogPost';
import BlogPosts from './views/examples/BlogPosts';
import ContactUs from './views/examples/ContactUs';
import Discover from './views/examples/Discover';
import Ecommerce from './views/examples/Ecommerce';
import Error404 from './views/examples/Error404';
import Error422 from './views/examples/Error422';
import Error500 from './views/examples/Error500';
import LandingPage from './views/examples/LandingPage';
import LoginPage from './views/examples/LoginPage';
import ProductPage from './views/examples/ProductPage';
import ProfilePage from './views/examples/ProfilePage';
import RegisterPage from './views/examples/RegisterPage';
import SearchWithSidebar from './views/examples/SearchWithSidebar';
import Settings from './views/examples/Settings';
import TwitterRedesign from './views/examples/TwitterRedesign';

// Alert Options
const alertOptions = {
  position: 'bottom center',
};

class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (
      <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <Router>
            <>
              <Alerts />
              <Switch>
                <Route exact path="/" component={SkillTree} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/login" component={Login} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute path="/index" component={Index} />
                <PrivateRoute path="/nucleo-icons" component={NucleoIcons} />
                <PrivateRoute path="/sections" component={Sections} />
                <PrivateRoute path="/presentation" component={Presentation} />
                <PrivateRoute path="/about-us" component={AboutUs} />
                <PrivateRoute path="/add-product" component={AddProduct} />
                <PrivateRoute path="/blog-post" component={BlogPost} />
                <PrivateRoute path="/blog-posts" component={BlogPosts} />
                <PrivateRoute path="/contact-us" component={ContactUs} />
                <PrivateRoute path="/discover" component={Discover} />
                <PrivateRoute path="/e-commerce" component={Ecommerce} />
                <PrivateRoute path="/error-404" component={Error404} />
                <PrivateRoute path="/error-422" component={Error422} />
                <PrivateRoute path="/error-500" component={Error500} />
                <PrivateRoute path="/landing-page" component={LandingPage} />
                <PrivateRoute path="/login-page" component={LoginPage} />
                <PrivateRoute path="/product-page" component={ProductPage} />
                <PrivateRoute path="/profile-page" component={ProfilePage} />
                <PrivateRoute path="/register-page" component={RegisterPage} />
                <PrivateRoute path="/search-with-sidebar" component={SearchWithSidebar} />
                <PrivateRoute path="/settings" component={Settings} />
                <PrivateRoute path="/twitter-redesign" component={TwitterRedesign} />
                <Redirect to="/" />
              </Switch>
            </>
          </Router>
        </AlertProvider>
      </Provider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('app'));
