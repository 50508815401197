import React from 'react';
import { Link } from 'react-router-dom';
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js';
// reactstrap components
import { Collapse, NavbarBrand, Navbar, Container, UncontrolledTooltip } from 'reactstrap';
// core components
import NavElement from '../layout/NavElement';

function WhiteNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [bodyClick, setBodyClick] = React.useState(false);

  React.useEffect(() => {
    const headroom = new Headroom(document.getElementById('navbar-main'));
    // initialise
    headroom.init();
  });
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="fixed-top" expand="lg" id="navbar-main">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/" tag={Link}>
              Calisthenics For A Year
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              Calisthenics For A Year
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <NavElement />
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default WhiteNavbar;
