import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components
import Header from '../../components/layout/Header';
import FooterWhite from '../../components/Footers/FooterWhite';
import SkillTree from '../../components/D3/SkillTree/SkillTree';

import Progressions from '../../data/Progressions';

function SkillTreePage() {
  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('skill-tree');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('skill-tree');
    };
  });
  return (
    <>
      <Header />
      <div className="wrapper">
        <div className="main">
          <div className="section">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="12" xs="12">
                  <SkillTree data={Progressions} />
                </Col>
              </Row>
            </Container>
          </div>

        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default SkillTreePage;
