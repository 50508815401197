import React, { useEffect } from 'react';
import { withAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const excludedErrorMessages = ['detail'];

function Alerts(props) {
  const { alert } = props;
  const messages = useSelector((state) => state.messages);
  const errors = useSelector((state) => state.errors);

  useEffect(() => {
    const errorMsgs = [];
    _.each(_.get(errors, 'msg'), (msg, key) => {
      if (!_.includes(excludedErrorMessages, key)) {
        if (_.isString(msg)) {
          msg = [msg];
        }
        errorMsgs.push(`${key}: ${msg.join(', ')}`);
      }
    });
    if (!_.isEmpty(errorMsgs)) _.each(errorMsgs, (msg) => alert.error(msg));
  }, [errors, alert]);
  useEffect(() => {
    if (messages.deleteSkill) alert.success(messages.deleteSkill);
    if (messages.addSkill) alert.success(messages.addSkill);
    if (messages.passwordNotMatch) alert.errors(messages.passwordNotMatch);
  }, [messages, alert]);

  return <></>;
}

export default withAlert()(Alerts);
