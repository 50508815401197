import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const tagPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
  PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
  PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
    ]),
  ),
]);

const propTypes = {
  tag: tagPropType,
  color: PropTypes.string,
  className: PropTypes.string,
  spinnerClass: PropTypes.string,
  loading: PropTypes.bool,
};

const defaultProps = {
  tag: 'div',
  color: '',
  className: '',
  spinnerClass: 'uil-reload-css',
  loading: false,
};

function LoadingMask(props) {
  const { className, color, tag: Tag, loading, spinnerClass, ...attributes } = props;
  const wrapperClasses = classNames(className, 'loading-mask-wrapper');
  const maskClasses = classNames({'loading-mask': true, 'invisible': !loading});
  const spinnerClasses = classNames(spinnerClass, 'loading-mask-spinner');


  return (
    <div className={wrapperClasses}>
      <div className={maskClasses}>
        <div className={spinnerClasses}>
          <div />
        </div>
      </div>
      <Tag {...attributes} />
    </div>
  );
}

LoadingMask.propTypes = propTypes;
LoadingMask.defaultProps = defaultProps;

export default LoadingMask;
