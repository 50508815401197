import _ from 'lodash';
import { GET_SKILLS, GET_SKILL, DELETE_SKILL, ADD_SKILL } from '../actions/types';

const initialState = {
  skills: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SKILLS:
      return {
        ...state,
        skills: _.isEmpty(action.payload)
          ? { noSkills: true }
          : _.mapValues(_.keyBy(action.payload, 'skillID')),
      };
    case GET_SKILL:
      return {
        ...state,
        skill: action.payload,
      };
    case DELETE_SKILL:
      return {
        ...state,
        skills: _.filter(state.skills, (skill) => skill.id !== action.payload),
      };
    case ADD_SKILL:
      return {
        ...state,
        skills: [...state.skills, action.payload],
      };

    default:
      return state;
  }
}
