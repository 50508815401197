import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getSkills, deleteSkill } from '../../actions/skills';

function Skills() {
  const skills = useSelector((state) => _.get(state, 'skills.skills', {}));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSkills());
  }, [dispatch]);

  return (
    <>
      <h2>Skills</h2>
      <div className="table-responsive">
        <table className="table table-striped table-condensed">
          <thead className="thead-dark">
            <tr>
              <th>identifier</th>
              <th>name</th>
              <th>difficulty</th>
              <th>category</th>
              <th>description</th>
              <th>videoUrl</th>
              <th>musclesUsed</th>
              <th>relatedInjuries</th>
              <th>otherNames</th>
              <th>owner</th>
              <th>createdAt</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {_.map(skills, (skill) => (
              <tr key={skill.skillID}>
                <td>{skill.skillID}</td>
                <td>{skill.name}</td>
                <td>{skill.difficulty}</td>
                <td>{skill.category}</td>
                <td>{skill.description}</td>
                <td>{skill.videoUrl}</td>
                <td>{skill.musclesUsed.join(', ')}</td>
                <td>{skill.relatedInjuries.join(', ')}</td>
                <td>{skill.otherNames.join(', ')}</td>
                <td>{skill.owner}</td>
                <td>{skill.createdAt}</td>
                <td>
                  <button
                    onClick={() => dispatch(deleteSkill(skill.id))}
                    type="button"
                    className="btn btn-danger btn-sm"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Skills;
