import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardTitle, Col, Container, Form, Input, Row } from 'reactstrap';
import LoadingMask from '../common/LoadingMask';
import { login } from '../../actions/auth';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const auth = useSelector((state) => state.auth);
  const errors = useSelector((state) => state.errors);
  const { isAuthenticated } = auth;
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(login(username, password));
    setLoading(true);
    setUsername('');
    setPassword('');
  };

  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    setLoading(false);
    // For some reason the login page card has horrible styles so we just simulate a register page
    document.body.classList.add('register-page');
    document.body.classList.add('full-screen');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('register-page');
      document.body.classList.remove('full-screen');
    };
  }, [auth, errors]);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <div className="wrapper login-page">
        <div
          className="page-header"
          style={{
            backgroundImage: `url(${require('../../assets/img/sections/bruno-abatti.jpg')})`,
          }}
        >
          <div className="filter" />
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="4" md="6" sm="6">
                <LoadingMask loading={loading}>
                  <Card className="card-register card">
                    <CardTitle className="text-center" tag="h3">
                      Welcome
                    </CardTitle>
                    <div className="social">
                      <Button className="btn-just-icon mr-1" color="facebook">
                        <i className="fa fa-facebook" />
                      </Button>
                      <Button className="btn-just-icon mr-1" color="google">
                        <i className="fa fa-google" />
                      </Button>
                      <Button className="btn-just-icon" color="twitter">
                        <i className="fa fa-twitter" />
                      </Button>
                    </div>
                    <div className="division">
                      <div className="line l" />
                      <span>or</span>
                      <div className="line r" />
                    </div>
                    <Form className="register-form" onSubmit={onSubmit}>
                      <Input
                        type="text"
                        className="no-border"
                        name="username"
                        placeholder="Username"
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                      />
                      <Input
                        type="password"
                        className="no-border"
                        name="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                      <Button block className="btn-round" color="success">
                        login
                      </Button>
                    </Form>
                    {/* <div className="login">
                      <p className="ml-auto mr-auto">
                        Don't have an account? <Link to="/register">Register</Link>
                      </p>
                    </div>
                    <div className="forgot">
                      <Link to="/register">
                        <Button
                          className="btn-link"
                          color="danger"
                        >
                          Forgot password?
                        </Button>
                      </Link>
                    </div>*/}
                  </Card>
                </LoadingMask>
              </Col>
            </Row>
            <div className="demo-footer text-center">
              <h6>© {new Date().getFullYear()} - Calisthenics For A Year</h6>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Login;
