import React from 'react';
import { Row, Col } from 'reactstrap';
import Skills from './Skills';

import Header from '../layout/Header';
import SkillForm from '../D3/SkillTree/SkillForm';

export default function Dashboard() {
  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: '100px' }}>
        <Row>
          <Col>
            <Skills />
          </Col>
        </Row>
      </div>
    </>
  );
}
