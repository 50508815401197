export default {
  root: {
    name: 'Calisthenics Exercise Progressions',
    root: true,
    children: [
      {
        name: 'Horizontal Pull',
        pathRoot: true,
        dataPath: 'hpull',
        children: null,
      },
      {
        name: 'Vertical Pull',
        pathRoot: true,
        dataPath: 'vpull',
        children: null,
      },
      {
        name: 'Vertical Push & Pull',
        pathRoot: true,
        dataPath: 'vpp',
        children: null,
      },
      {
        name: 'Vertical Push',
        pathRoot: true,
        dataPath: 'vpush',
        children: null,
      },
      {
        name: 'Horizontal Push',
        pathRoot: true,
        dataPath: 'hpush',
        children: null,
      },
      {
        name: 'Core',
        pathRoot: true,
        dataPath: 'core',
        children: null,
      },
      {
        name: 'Legs',
        pathRoot: true,
        dataPath: 'legs',
        children: null,
      },
    ],
  },
  hpull: {
    name: 'Horizontal Pull',
    pathRoot: true,
    dataPath: 'root',
    children: [
      {
        name: 'German Hang',
        difficulty: 'beginner',
        videoURL: 'https://www.youtube.com/embed/mNBYfyEbCeA',
        children: [
          {
            name: 'Skin the Cat',
            milestone: true,
            difficulty: 'beginner',
            videoURL: 'https://www.youtube.com/embed/7JdffYq-2ho',
            children: [
              {
                name: 'Tuck Back Lever',
                difficulty: 'beginner',
                children: [
                  {
                    name: 'Adv Tuck Back Lever',
                    difficulty: 'beginner',
                    children: [
                      {
                        name: 'Straddle Back Lever',
                        difficulty: 'intermediate',
                        children: [
                          {
                            name: 'One leg Back Lever',
                            difficulty: 'intermediate',
                            children: [
                              {
                                name: 'Back Lever',
                                milestone: true,
                                difficulty: 'intermediate',
                                videoURL: 'https://www.youtube.com/embed/1yQJ9vA-Uhw',
                                children: [
                                  {
                                    name: 'BL Pullout',
                                    difficulty: 'intermediate',
                                    children: [
                                      {
                                        name: 'German Hang Pullout',
                                        difficulty: 'advanced',
                                        children: [
                                          {
                                            name: 'Bent Arm Pull up to BL',
                                            difficulty: 'advanced',
                                            children: [
                                              {
                                                name: 'Handstand to Hefesto',
                                                milestone: true,
                                                videoURL:
                                                  'https://www.youtube.com/embed/ZYRXtP7rfjY',

                                                difficulty: 'advanced',
                                                children: null,
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'L-Hang',
        difficulty: 'beginner',
        children: [
          {
            name: 'Tuck Front Lever',
            difficulty: 'beginner',
            children: [
              {
                name: 'Adv Tuck Front Lever',
                difficulty: 'intermediate',
                children: [
                  {
                    name: 'Straddle Front Lever',
                    difficulty: 'intermediate',
                    children: [
                      {
                        name: 'One leg Front Lever',
                        difficulty: 'intermediate',
                        children: [
                          {
                            name: 'Front Lever',
                            milestone: true,
                            difficulty: 'intermediate',
                            videoURL: 'https://www.youtube.com/embed/pyVyLPfJpQY',

                            children: [
                              {
                                name: 'Front Lever to Inverted',
                                difficulty: 'advanced',
                                children: [
                                  {
                                    name: 'Hanging Pull FL to Inverted',
                                    difficulty: 'advanced',
                                    children: [
                                      {
                                        name: '360 Pull',
                                        videoURL:
                                          'https://www.youtube.com/embed/9fww18IPxMw?start=22',
                                        milestone: true,
                                        difficulty: 'advanced',
                                        children: null,
                                      },
                                      {
                                        name: 'Circle Front Lever',
                                        milestone: true,
                                        videoURL:
                                          'https://www.youtube.com/embed/ZiUXNRmnC9o?start=4',

                                        difficulty: 'advanced',
                                        children: null,
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Vertical Row',
        difficulty: 'beginner',
        children: [
          {
            name: 'Incline Row',
            difficulty: 'beginner',
            children: [
              {
                name: 'Row',
                milestone: true,
                videoURL: 'https://www.youtube.com/embed/qlry7NuFiFY?start=80',
                difficulty: 'beginner',
                children: [
                  {
                    name: 'Wide Row',
                    difficulty: 'beginner',
                    children: [
                      {
                        name: 'Archer Row',
                        difficulty: 'beginner',
                        children: [
                          {
                            name: 'Tuck FL Row',
                            difficulty: 'intermediate',
                            children: [
                              {
                                name: 'Adv FL Row',
                                difficulty: 'intermediate',
                                children: [
                                  {
                                    name: 'Straddle FL Row',
                                    difficulty: 'advanced',
                                    children: [
                                      {
                                        name: 'FL Row',
                                        difficulty: 'advanced',
                                        children: null,
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            name: 'Straddle One Arm',
                            difficulty: 'intermediate',
                            children: [
                              {
                                name: 'One Arm Row',
                                videoURL: 'https://www.youtube.com/embed/15zd96I5Oa8',
                                milestone: true,
                                difficulty: 'intermediate',
                                children: null,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  vpull: {
    name: 'Vertical Pull',
    pathRoot: true,
    dataPath: 'root',
    children: [
      {
        name: 'Scapular Shrug',
        difficulty: 'beginner',
        children: [
          {
            name: 'Arch Hang',
            difficulty: 'beginner',
            children: [
              {
                name: 'Pull Up Negative',
                difficulty: 'beginner',
                children: [
                  {
                    name: 'Pull Up',
                    videoURL: 'https://www.youtube.com/embed/eGo4IYlbE5g',
                    milestone: true,
                    difficulty: 'beginner',
                    children: [
                      {
                        name: 'L-sit Pull Up',
                        difficulty: 'beginner',
                        children: [
                          {
                            name: 'Wide Pull Up',
                            difficulty: 'intermediate',
                            children: [
                              {
                                name: 'Wide L-Pull Up',
                                difficulty: 'intermediate',
                                children: [
                                  {
                                    name: 'Typewriter Pull Up',
                                    difficulty: 'intermediate',
                                    children: [
                                      {
                                        name: 'Archer Pull Up',
                                        difficulty: 'intermediate',
                                        children: [
                                          {
                                            name: 'One Arm Pull Up Negative',
                                            difficulty: 'intermediate',
                                            children: [
                                              {
                                                name: 'One Arm Pull Up',
                                                milestone: true,
                                                videoURL:
                                                  'https://www.youtube.com/embed/vdjWgw98EeI',
                                                difficulty: 'advanced',
                                                children: null,
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            name: 'Pull Over',
                            difficulty: 'intermediate',
                            children: null,
                          },
                          {
                            name: 'Kipping Pull Up',
                            difficulty: 'beginner',
                            children: [
                              {
                                name: 'Chest to Bar PU',
                                difficulty: 'beginner',

                                children: [
                                  {
                                    name: 'Muscle Up Neg',
                                    difficulty: 'beginner',

                                    children: [
                                      {
                                        name: 'Kipping Muscle Up',
                                        difficulty: 'beginner',

                                        children: [
                                          {
                                            name: 'Muscle Up',
                                            milestone: true,
                                            videoURL:
                                              'https://www.youtube.com/embed/pS1A--3mNZ4?start=15',
                                            difficulty: 'intermediate',
                                            children: [
                                              {
                                                name: 'Wide Muscle Up',
                                                difficulty: 'intermediate',
                                                children: [
                                                  {
                                                    name: 'Strict Bar Muscle Up',
                                                    difficulty: 'intermediate',
                                                    children: [
                                                      {
                                                        name: 'L-sit Muscle Up',
                                                        difficulty: 'intermediate',
                                                        children: [
                                                          {
                                                            name: 'One Arm Muscle Up',
                                                            milestone: true,
                                                            videoURL:
                                                              'https://www.youtube.com/embed/ob94c4do1Ic?start=30',
                                                            difficulty: 'advanced',
                                                            children: null,
                                                          },
                                                        ],
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  vpp: {
    name: 'Vertical Push & Pull',
    pathRoot: true,
    dataPath: 'root',
    children: [
      {
        name: 'Side Plank',
        difficulty: 'beginner',
        children: [
          {
            name: 'Tuck flag',
            difficulty: 'intermediate',
            children: [
              {
                name: 'Adv Tuck Flag',
                difficulty: 'intermediate',
                children: [
                  {
                    name: 'Straddle Flag',
                    difficulty: 'intermediate',
                    children: [
                      {
                        name: 'Human Flag',
                        milestone: true,
                        videoURL: 'https://www.youtube.com/embed/v73VULYZWCI',
                        difficulty: 'beginner',
                        children: null,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  vpush: {
    name: 'Vertical Push',
    pathRoot: true,
    dataPath: 'root',
    children: [
      {
        name: 'Foot Sup L-Sit',
        difficulty: 'beginner',
        children: [
          {
            name: 'One Leg L-sit',
            difficulty: 'beginner',
            children: [
              {
                name: 'Tuck L-sit',
                difficulty: 'beginner',
                children: [
                  {
                    name: 'OL Bent L-sit',
                    difficulty: 'beginner',
                    children: [
                      {
                        name: 'L-sit',
                        videoURL: 'https://www.youtube.com/embed/cu0fHp8HCDo',
                        milestone: true,
                        difficulty: 'beginner',
                        children: [
                          {
                            name: 'Straddle L-sit',
                            difficulty: 'beginner',
                            children: [
                              {
                                name: 'RTO L-sit',
                                difficulty: 'intermediate',
                                children: [
                                  {
                                    name: '45° V-sit',
                                    milestone: true,
                                    videoURL: 'https://www.youtube.com/embed/emyOfFK2S8o?start=92',
                                    difficulty: 'intermediate',
                                    children: [
                                      {
                                        name: '75° V-sit',
                                        difficulty: 'intermediate',
                                        children: [
                                          {
                                            name: '100° V-sit (I-sit)',
                                            videoURL:
                                              'https://www.youtube.com/embed/P83rvEDFTjg?start=10',
                                            difficulty: 'intermediate',
                                            children: [
                                              {
                                                name: '120° V-sit',
                                                difficulty: 'advanced',
                                                children: [
                                                  {
                                                    name: '140° V-sit',
                                                    difficulty: 'advanced',
                                                    children: [
                                                      {
                                                        name: '155° V-sit',
                                                        difficulty: 'advanced',
                                                        children: [
                                                          {
                                                            name: '170° V-sit',
                                                            difficulty: 'advanced',
                                                            children: [
                                                              {
                                                                name: 'Manna',
                                                                milestone: true,
                                                                videoURL:
                                                                  'https://www.youtube.com/embed/L7reIo5f6uo?start=40',
                                                                difficulty: 'advanced',
                                                                children: null,
                                                              },
                                                            ],
                                                          },
                                                        ],
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Reverse Plank',
        difficulty: 'beginner',
        children: [
          {
            name: 'Shoulder Bridge',
            difficulty: 'beginner',
            children: [
              {
                name: 'Table Bridge',
                difficulty: 'beginner',
                children: [
                  {
                    name: 'Angled Bridge',
                    difficulty: 'beginner',
                    children: [
                      {
                        name: 'Head Bridge',
                        difficulty: 'beginner',
                        children: [
                          {
                            name: 'Bridge',
                            milestone: true,
                            videoURL: 'https://www.youtube.com/embed/WhYis4MMoak',
                            difficulty: 'beginner',
                            children: [
                              {
                                name: 'Decline Bridge',
                                difficulty: 'beginner',
                                children: [
                                  {
                                    name: 'One Leg Bridge',
                                    difficulty: 'beginner',
                                    children: [
                                      {
                                        name: 'Decline OL Bridge',
                                        difficulty: 'beginner',
                                        children: [
                                          {
                                            name: 'OA Head Bridge',
                                            difficulty: 'intermediate',
                                            children: [
                                              {
                                                name: 'One Arm Bridge',
                                                milestone: true,
                                                videoURL:
                                                  'https://www.youtube.com/embed/19drUP1sW08?start=157',
                                                difficulty: 'intermediate',
                                                children: null,
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Wall Plank',
        difficulty: 'beginner',
        children: [
          {
            name: 'Wall HeS',
            difficulty: 'beginner',
            children: [
              {
                name: 'Wall HS',
                difficulty: 'beginner',
                children: [
                  {
                    name: 'Handstand',
                    milestone: true,
                    videoURL: 'https://www.youtube.com/embed/WFabFPU5258?start=10',
                    difficulty: 'beginner',
                    children: [
                      {
                        name: 'Ring Shoulder Stand',
                        difficulty: 'intermediate',
                        children: [
                          {
                            name: 'Ring Handstand',
                            milestone: true,
                            videoURL: 'https://www.youtube.com/embed/UU2gnuYZYcE?start=140',
                            difficulty: 'intermediate',
                            children: null,
                          },
                        ],
                      },
                      {
                        name: 'Progression',
                        difficulty: 'intermediate',
                        children: [
                          {
                            name: 'One Arm Handstand',
                            milestone: true,
                            videoURL: 'https://www.youtube.com/embed/82lZVOJKCAE?start=8',
                            difficulty: 'advanced',
                            children: null,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: 'Wall HeS PU Neg',
                    difficulty: 'beginner',
                    children: [
                      {
                        name: 'Wall HeS PU',
                        difficulty: 'beginner',
                        children: [
                          {
                            name: 'Wall HS PU',
                            difficulty: 'intermediate',
                            children: [
                              {
                                name: 'HeS PU',
                                difficulty: 'intermediate',
                                children: [
                                  {
                                    name: 'Handstand PU',
                                    videoURL: 'https://www.youtube.com/embed/Y9gF0C2sYVA',
                                    milestone: true,
                                    difficulty: 'intermediate',
                                    children: null,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Support Hold',
        difficulty: 'beginner',
        children: [
          {
            name: 'Dip Neg',
            difficulty: 'beginner',
            children: [
              {
                name: 'Dip',
                milestone: true,
                videoURL: 'https://www.youtube.com/embed/2z8JmcrW-As',
                difficulty: 'beginner',
                children: [
                  {
                    name: 'L-sit Dip',
                    difficulty: 'beginner',
                    children: null,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Ring Support Hold',
        difficulty: 'beginner',
        children: [
          {
            name: 'RTO Support Hold',
            difficulty: 'beginner',
            children: [
              {
                name: 'Ring Dip Neg',
                difficulty: 'beginner',
                children: [
                  {
                    name: 'Ring Dip',
                    milestone: true,
                    videoURL: 'https://www.youtube.com/embed/EznLCDBAPIU',
                    difficulty: 'beginner',
                    children: [
                      {
                        name: 'RTO Dip',
                        difficulty: 'beginner',
                        children: [
                          {
                            name: 'Bulgarian Dip',
                            difficulty: 'beginner',
                            children: [
                              {
                                name: 'Ring L-sit Dip',
                                milestone: true,
                                videoURL: 'https://www.youtube.com/embed/zMJpC7y-6SE',
                                difficulty: 'intermediate',
                                children: [
                                  {
                                    name: 'Ring Wide Dip',
                                    difficulty: 'intermediate',
                                    children: [
                                      {
                                        name: 'RTO 45° Dip',
                                        difficulty: 'intermediate',
                                        children: [
                                          {
                                            name: 'RTO 90° DIP',
                                            milestone: true,
                                            videoURL:
                                              'https://www.youtube.com/embed/cLkkMqoRT3Q?start=211',
                                            difficulty: 'advanced',
                                            children: null,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  hpush: {
    name: 'Horizontal Push',
    pathRoot: true,
    dataPath: 'root',
    children: [
      {
        name: 'Incline Push Up',
        difficulty: 'beginner',
        children: [
          {
            name: 'Push Up',
            videoURL: 'https://www.youtube.com/embed/IODxDxX7oi4',
            milestone: true,
            difficulty: 'beginner',
            children: [
              {
                name: 'Diamond Push Up',
                difficulty: 'beginner',
                children: [
                  {
                    name: 'PP Push Up',
                    difficulty: 'beginner',
                    children: [
                      {
                        name: 'Pike Push Up',
                        difficulty: 'beginner',
                        children: [
                          {
                            name: 'Decline Pike Push Up',
                            difficulty: 'beginner',
                            children: [
                              {
                                name: 'Archer Push Up',
                                difficulty: 'beginner',
                                children: [
                                  {
                                    name: 'Incline OA PU',
                                    difficulty: 'intermediate',
                                    children: [
                                      {
                                        name: 'Straddle OA PU',
                                        difficulty: 'intermediate',
                                        children: [
                                          {
                                            name: 'Ring Str OA PU',
                                            difficulty: 'intermediate',
                                            children: [
                                              {
                                                name: 'OA PU',
                                                milestone: true,
                                                videoURL:
                                                  'https://www.youtube.com/embed/yYLngtihzQ0?start=10',
                                                difficulty: 'intermediate',
                                                children: [
                                                  {
                                                    name: 'Ring OA PU',
                                                    videoURL:
                                                      'https://www.youtube.com/embed/jgc8mVcxwQE',

                                                    milestone: true,
                                                    difficulty: 'advanced',
                                                    children: null,
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: 'Ring Wide PU',
                    difficulty: 'beginner',
                    children: [
                      {
                        name: 'Ring PU',
                        difficulty: 'beginner',
                        children: [
                          {
                            name: 'RTO PU',
                            difficulty: 'intermediate',
                            children: [
                              {
                                name: 'RTO Archer Push Up',
                                difficulty: 'intermediate',
                                children: [
                                  {
                                    name: 'RTO PP PU',
                                    difficulty: 'intermediate',
                                    children: [
                                      {
                                        name: 'RTO Maltese PU',
                                        milestone: true,
                                        videoURL: 'https://www.youtube.com/embed/GUtcCXvDyeA',
                                        difficulty: 'advanced',
                                        children: [
                                          {
                                            name: 'Ring Wall PP PU',
                                            difficulty: 'advanced',
                                            children: [
                                              {
                                                name: 'Wall PP PU',
                                                difficulty: 'advanced',
                                                children: [
                                                  {
                                                    name: 'Wall Maltese PU',
                                                    difficulty: 'advanced',
                                                    children: [
                                                      {
                                                        name: 'Ring Wall Maltese PU',
                                                        milestone: true,
                                                        difficulty: 'elite',
                                                        children: [
                                                          {
                                                            name: 'Pelican',
                                                            milestone: true,
                                                            videoURL:
                                                              'https://www.youtube.com/embed/ScvLFdof3ys?start=20',
                                                            difficulty: 'elite',
                                                            children: null,
                                                          },
                                                        ],
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        name: 'Planche Lean',
        difficulty: 'beginner',
        children: [
          {
            name: 'Frog Stand',
            milestone: true,
            videoURL: 'https://www.youtube.com/embed/kKVJuYVhL1U',
            difficulty: 'beginner',
            children: [
              {
                name: 'Straight Arm Frog Stand',
                difficulty: 'beginner',
                children: [
                  {
                    name: 'Tuck Planche',
                    difficulty: 'intermediate',
                    children: [
                      {
                        name: 'Adv Tuck Planche',
                        difficulty: 'intermediate',
                        children: [
                          {
                            name: 'Str Planche',
                            difficulty: 'intermediate',
                            children: [
                              {
                                name: 'One Leg Planche',
                                difficulty: 'advanced',
                                children: [
                                  {
                                    name: 'Planche',
                                    milestone: true,
                                    videoURL: 'https://www.youtube.com/embed/UZ-1jwG7aQ4?start=26',
                                    difficulty: 'advanced',
                                    children: [
                                      {
                                        name: 'Maltese',
                                        milestone: true,
                                        videoURL: 'https://www.youtube.com/embed/5UUCEqVJ4kg',
                                        difficulty: 'elite',
                                        children: null,
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        name: 'Tuck PL PU',
                        difficulty: 'intermediate',
                        children: [
                          {
                            name: 'Adv Tuck PL PU',
                            difficulty: 'intermediate',
                            children: [
                              {
                                name: 'Str PL PU',
                                milestone: true,
                                difficulty: 'advanced',
                                children: [
                                  {
                                    name: 'Planche Push Up',
                                    milestone: true,
                                    videoURL: 'https://www.youtube.com/embed/kw0kXtvOpfU?start=5',
                                    difficulty: 'elite',
                                    children: null,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: 'Straddle Elbow Lever',
                    difficulty: 'beginner',
                    children: [
                      {
                        name: 'Elbow Lever',
                        milestone: true,
                        videoURL: 'https://www.youtube.com/embed/ZNCFXIIB0eQ',
                        difficulty: 'beginner',
                        children: [
                          {
                            name: 'Ring Elbow Lever',
                            difficulty: 'intermediate',
                            children: [
                              {
                                name: 'OA Str Elbow Lever',
                                difficulty: 'intermediate',
                                children: [
                                  {
                                    name: 'OA Elbow Lever',
                                    videoURL: 'https://www.youtube.com/embed/8fPa9sSL53g?start=7',
                                    milestone: true,
                                    difficulty: 'intermediate',
                                    children: null,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  core: {
    name: 'Core',
    pathRoot: true,
    dataPath: 'root',
    children: [
      {
        name: 'Hollow Hold',
        difficulty: 'beginner',
        children: [
          {
            name: 'Tuck Up Crunch',
            difficulty: 'beginner',
            children: [
              {
                name: 'Seated Pike Leg Lift',
                difficulty: 'beginner',
                children: [
                  {
                    name: 'Tuck Dragon Flag Neg',
                    difficulty: 'beginner',
                    children: [
                      {
                        name: 'Adv Tuck Dragon Flag',
                        difficulty: 'beginner',
                        children: [
                          {
                            name: 'Str Dragon Flag',
                            difficulty: 'intermediate',
                            children: [
                              {
                                name: 'Dragon Flag',
                                videoURL: 'https://www.youtube.com/embed/qoAuAvPcfwM?start=5',
                                milestone: true,
                                difficulty: 'intermediate',
                                children: [
                                  {
                                    name: 'Ankle Weight Dragon Flag',
                                    difficulty: 'advanced',
                                    children: [
                                      {
                                        name: 'One Arm Dragon Flag',
                                        videoURL:
                                          'https://www.youtube.com/embed/qoAuAvPcfwM?start=21',
                                        milestone: true,
                                        difficulty: 'advanced',
                                        children: null,
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: 'Handing Knees to Chest',
                    difficulty: 'beginner',
                    children: [
                      {
                        name: 'Hanging Leg Raise T2B',
                        videoURL: 'https://www.youtube.com/embed/Mo5zdUSuok0',
                        milestone: true,
                        difficulty: 'beginner',
                        children: [
                          {
                            name: 'Ankle Weight HLR T2B',
                            difficulty: 'beginner',
                            children: [
                              {
                                name: 'OA HLR T2B',
                                milestone: true,
                                videoURL: 'https://www.youtube.com/embed/sEV28ms0OEA',
                                difficulty: 'beginner',
                                children: null,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Banded Pallof Press',
        difficulty: 'beginner',
        children: [
          {
            name: 'Ring Pallof Press',
            difficulty: 'beginner',
            children: null,
          },
        ],
      },
      {
        name: 'Rev Hyperextension',
        difficulty: 'beginner',
        children: [
          {
            name: 'Hyperextension',
            difficulty: 'beginner',
            children: [
              {
                name: 'Arch Body Hold',
                difficulty: 'beginner',
                children: null,
              },
            ],
          },
        ],
      },
      {
        name: 'Plank',
        difficulty: 'beginner',
        children: [
          {
            name: 'One Arm Plank',
            difficulty: 'beginner',
            children: [
              {
                name: 'OA OL Plank',
                difficulty: 'beginner',
                children: [
                  {
                    name: 'Ring Ab Rollout',
                    difficulty: 'intermediate',
                    children: [
                      {
                        name: 'Knees Ab Wheel',
                        difficulty: 'intermediate',
                        children: [
                          {
                            name: 'Straight Leg Ab Wheel Ramp',
                            difficulty: 'intermediate',
                            children: [
                              {
                                name: 'Straight Leg Ab Wheel Neg',
                                difficulty: 'intermediate',
                                children: [
                                  {
                                    name: 'Straight Leg Ab Wheel',
                                    videoURL: 'https://www.youtube.com/embed/JL_h9SPJDmg?start=54',
                                    milestone: true,
                                    difficulty: 'intermediate',
                                    children: [
                                      {
                                        name: 'Weighted Ab Wheel',
                                        difficulty: 'advanced',
                                        children: [
                                          {
                                            name: 'One Arm Ab Wheel',
                                            videoURL: 'https://www.youtube.com/embed/0Yy2EYiMWS0',
                                            milestone: true,
                                            difficulty: 'advanced',
                                            children: null,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  legs: {
    name: 'Legs',
    pathRoot: true,
    dataPath: 'root',
    children: [
      {
        name: 'Assisted Squat',
        difficulty: 'beginner',
        children: [
          {
            name: 'Parallel Squat',
            difficulty: 'beginner',
            children: [
              {
                name: 'Full Squat',
                videoURL: 'https://www.youtube.com/embed/X0qC1k0Zi6k?start=11',
                milestone: true,
                difficulty: 'beginner',
                children: [
                  {
                    name: 'Cossack Squat',
                    difficulty: 'beginner',
                    children: [
                      {
                        name: 'Partial Pistol Squat',
                        difficulty: 'beginner',
                        children: [
                          {
                            name: 'Assisted Pistol Squat',
                            difficulty: 'beginner',
                            children: [
                              {
                                name: 'Pistol Squat',
                                videoURL: 'https://www.youtube.com/embed/R1mxpLzYgxM',
                                difficulty: 'beginner',
                                milestone: true,
                                children: [
                                  {
                                    name: 'Natural OL Press',
                                    videoURL: 'https://www.youtube.com/embed/g5p1FVxOHX8',
                                    milestone: true,
                                    difficulty: 'intermediate',
                                    children: [
                                      {
                                        name: 'Weighted Pistol Squat',
                                        difficulty: 'intermediate',
                                        children: [
                                          {
                                            name: 'Elevated Friction Pistol Squat',
                                            difficulty: 'advanced',
                                            children: null,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: 'Split Squat',
                    difficulty: 'beginner',
                    children: [
                      {
                        name: 'Bulgarian Split Squat',
                        difficulty: 'beginner',
                        children: [
                          {
                            name: 'Step Up',
                            difficulty: 'beginner',
                            children: [
                              {
                                name: 'Deep Step Up',
                                difficulty: 'beginner',
                                children: null,
                              },
                            ],
                          },
                          {
                            name: 'Beginner Shrimp Squat',
                            difficulty: 'beginner',
                            children: [
                              {
                                name: 'Intermediate Shrimp Squat',
                                difficulty: 'beginner',
                                children: [
                                  {
                                    name: 'Adv Shrimp Squat',
                                    milestone: true,
                                    videoURL: 'https://www.youtube.com/embed/_FBuC-VPbRY',
                                    difficulty: 'intermediate',
                                    children: [
                                      {
                                        name: 'Two Hand Shrimp Squat',
                                        difficulty: 'intermediate',
                                        children: [
                                          {
                                            name: 'Elevated 2H Shrimp Squat',
                                            difficulty: 'intermediate',
                                            children: null,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'One leg deadlift',
        difficulty: 'beginner',
        children: [
          {
            name: '90 Hip NC',
            difficulty: 'beginner',
            children: [
              {
                name: '45 Hip NC',
                difficulty: 'beginner',
                children: [
                  {
                    name: 'Nordic Curl Negative',
                    difficulty: 'intermediate',
                    children: [
                      {
                        name: 'Nordic Curl',
                        milestone: true,
                        videoURL: 'https://www.youtube.com/embed/DQQleh4xUjU',
                        difficulty: 'intermediate',
                        children: [
                          {
                            name: 'Nordic Curl Arms Overhead',
                            difficulty: 'intermediate',
                            children: [
                              {
                                name: 'Tuck One Leg Nordic Curl',
                                difficulty: 'intermediate',
                                children: [
                                  {
                                    name: 'One Leg Nordic Curl',
                                    videoURL: 'https://www.youtube.com/embed/0LgsFpczRNc',
                                    milestone: true,
                                    difficulty: 'advanced',
                                    children: null,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
