import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getSkills } from '../../../actions/skills';
import SkillTreeModal from './SkillTreeModal';

/* Component */
function SkillTree(props) {
  const { data } = props;
  const dispatch = useDispatch();
  const skills = useSelector((state) => _.get(state, 'skills.skills'));

  const [notice, setNotice] = React.useState(false);
  const [modalData, setModalData] = React.useState({});
  const [updateD3, setUpdateD3] = React.useState(true);
  const [width, setWidth] = React.useState(1000);

  useEffect(() => {
    dispatch(getSkills());
  }, [dispatch]);

  let { newNodeID, svg, tree, rootNode, category } = {
    newNodeID: 0,
    svg: null,
    tree: null,
    rootNode: null,
    category: '',
  };

  const d3Container = useRef(null);
  const transitionDuration = 750;
  const margin = { top: 40, right: 30, bottom: 30, left: 30 };
  const height = 1300 - margin.top - margin.bottom;

  function generateGroupNodeAtts(nodeElements) {
    const attrs = {
      class: (node) => {
        const classes = ['node node-group'];
        if (node.data.root) {
          classes.push('node--root');
        }
        if (node.data.rootNode) {
          classes.push('node--root-node');
        }
        if (node.data.milestone) {
          classes.push('node--milestone');
        }

        switch (node.data.difficulty) {
          case 'beginner':
            classes.push('node--beginner');
            break;
          case 'intermediate':
            classes.push('node--intermediate');
            break;
          case 'advanced':
            classes.push('node--advanced');
            break;
          case 'elite':
            classes.push('node--elite');
            break;
          default:
            break;
        }
        return classes.join(' ');
      },
    };

    nodeElements.attr('class', attrs.class);
  }

  function generateRectNodeAtts(nodeElements) {
    const attrs = {
      class: () => {
        const classes = ['node', 'rect'];
        return classes.join(' ');
      },
      x: -8,
      y: -8,
      width: 16,
      height: 16,
    };

    nodeElements
      .attr('class', attrs.class)
      .attr('x', attrs.x)
      .attr('y', attrs.y)
      .attr('width', attrs.width)
      .attr('height', attrs.height);
  }
  function generateTextNodeAtts(nodeElements) {
    const attrs = {
      class: (node) => {
        const classes = ['label'];
        if (node.data.rootNode) {
          classes.push('title');
        }
        return classes.join(' ');
      },
      dy: -15,
      x: 0,
      'text-anchor': 'middle',
      text: (node) => node.data.name,
      opacity: 0,
    };

    nodeElements
      .attr('class', attrs.class)
      .attr('dy', attrs.dy)
      .attr('x', attrs.x)
      .attr('text-anchor', attrs['text-anchor'])
      .style('opacity', attrs.opacity)
      .text(attrs.text);
  }

  function updateNodes(nodesData, source) {
    // Normalize for fixed-depth.
    nodesData.forEach((node) => {
      node.y = node.depth * 80;
      if (category) {
        node.data.catagory = category;
      }
    });

    // ****************** Nodes section ***************************

    // Update the nodes...
    const nodes = svg.selectAll('g.node').data(nodesData, (d) => {
      return d.id || (d.id = ++newNodeID);
    });

    // Enter any new sodes at the parent's previous position.
    const newNodes = nodes
      .enter()
      .append('g')
      .attr('id', (node) => `node-${node.id}`)
      .attr('transform', (d) => `translate(${source.x0 || width / 2},${source.y0 || 0})`)
      .on('click', onNodeClick);

    generateGroupNodeAtts(newNodes);

    // Add Rect for the nodes that aren't the root
    const rectNodes = newNodes.filter((d) => !d.data.root).append('rect');
    generateRectNodeAtts(rectNodes);

    // Add labels for the nodes
    const textNodes = newNodes.append('text');
    generateTextNodeAtts(textNodes);

    // UPDATE
    const updatedNodes = newNodes.merge(nodes);

    // Transition to the proper position for the node
    updatedNodes
      .transition()
      .duration(transitionDuration)
      .attr('transform', (d) => {
        return `translate(${d.x},${d.y})`;
      });

    // Update the node attributes and style
    updatedNodes.select('.label').transition().duration(transitionDuration).style('opacity', 1);

    // Remove any exiting nodes
    const removedNodes = nodes
      .exit()
      .transition()
      .duration(transitionDuration)
      .attr('transform', (d) => {
        return `translate(${source.x},${source.y})`;
      })
      .remove();

    // On exit reduce the opacity of text labels
    removedNodes.select('text').style('fill-opacity', 1e-6);
    // Store the old positions for transition.
    nodesData.forEach((d) => {
      d.x0 = d.x;
      d.y0 = d.y;
    });
  }

  function diagonal(s, node) {
    return `M ${s.x} ${s.y} C ${(s.x + node.x) / 2} ${s.y}, ${(s.x + node.x) / 2} ${node.y}, ${
      node.x
    } ${node.y}`;
  }

  function updateLinks(links, source) {
    // ****************** links section ***************************
    // Update the links...
    const link = svg.selectAll('path.link').data(links, (d) => {
      return d.id;
    });

    // Enter any new links at the parent's previous position.
    const linkEnter = link
      .enter()
      .insert('path', 'g')
      .attr('class', 'link')
      .attr('d', (d) => {
        const o = { x: source.x0, y: source.y0 };
        return diagonal(o, o);
      });

    // UPDATE
    const linkUpdate = linkEnter.merge(link);

    // Transition back to the parent element position
    linkUpdate
      .transition()
      .duration(transitionDuration)
      .attr('d', (d) => {
        return diagonal(d, d.parent);
      });

    // Remove any exiting links
    link
      .exit()
      .transition()
      .duration(transitionDuration)
      .attr('d', () => {
        const o = { x: source.x, y: source.y };
        return diagonal(o, o);
      })
      .remove();
  }

  function update(source) {
    // Assigns the x and y position for the nodes
    const treeData = tree(rootNode);

    // Compute the new tree layout.
    const nodes = treeData.descendants();
    const links = treeData.descendants().slice(1);

    updateNodes(nodes, source);
    updateLinks(links, source);
  }
  function updateRoot(data) {
    rootNode = d3.hierarchy(data);
    rootNode.x0 = width / 2;
    rootNode.y0 = 0;
    rootNode.data.rootNode = true;
    update(rootNode);
  }

  function onNodeClick(node) {
    if (node.data.root) {
      return;
    }
    if (node.data.pathRoot) {
      category = node.data.name;
      updateRoot(data[node.data.dataPath]);
      return;
    }
    node.data.skillID = node.data.name.replace(/\s+/g, '').toLowerCase();
    node.data = _.merge(node.data, skills[node.data.skillID]);
    node.data.category = category;

    setModalData(_.merge(node.data, skills[node.data.skillID]));
    setNotice(true);
  }

  useEffect(() => {
    if (data && d3Container.current && updateD3 && !_.isEmpty(skills)) {
      setWidth(d3Container.current.parentElement.clientWidth - margin.left - margin.right);

      svg = d3
        .select(d3Container.current)
        .attr('width', width + margin.right + margin.left)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      tree = d3.tree().size([width, height]);
      updateRoot(data.root);
      setUpdateD3(false);
    }
  }, [data, d3Container.current, skills]);

  return (
    <div className="d3-component-container">
      <svg className="d3-component" ref={d3Container} />
      ;
      <SkillTreeModal
        isOpen={notice}
        toggle={() => {
          setNotice(false);
          setModalData({});
        }}
        data={modalData}
      />
    </div>
  );
}

export default SkillTree;
