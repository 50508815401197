import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingMask from './LoadingMask';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.isAuthenticated) {
          if (auth.isLoading || auth.token) {
            return <LoadingMask loading />;
          }
          return <Redirect to="/login" />;
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
