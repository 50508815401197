import React  from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';
import TagsInput from 'react-tagsinput';
import Form from 'reactstrap/es/Form';
import { addSkill } from '../../../actions/skills';
import ImageUpload from '../../CustomUpload/ImageUpload';

function SkillForm(props = {}) {
  const { data = {}, isTest = false } = props;
  const {
    description = '',
    difficulty = 'default',
    category = 'default',
    musclesUsed = [],
    relatedInjuries = [],
    otherNames = [],
    videoURL = '',
    name = '',
    skillID = '',
    id
  } = data;
  const [_description, setDescription] = React.useState(description);
  const [_musclesUsed, setMusclesUsed] = React.useState(musclesUsed);
  const [_relatedInjuries, setRelatedInjuries] = React.useState(relatedInjuries);
  const [_otherNames, setOtherNames] = React.useState(otherNames);
  const [_videoURL, setVideoURL] = React.useState(videoURL);
  const [_name, setName] = React.useState(name);
  const [_skillID, setSkillID] = React.useState(skillID);
  const [_imageFile, setImageFile] = React.useState(null);
  const _difficulty = difficulty ? [difficulty] : [];
  const _category = category ? [category] : [];
  const dispatch = useDispatch();


  const onSubmit = (e) => {
    e.preventDefault();
    const skillPayload = {
      id,
      description: _description,
      musclesUsed: _musclesUsed,
      relatedInjuries: _relatedInjuries,
      otherNames: _otherNames,
      video_url: _videoURL,
      name: _name,
      skillID: _skillID,
      difficulty: _difficulty.join(''),
      category: _category.join(''),
    };
    dispatch(addSkill(skillPayload));
  };
  return (
    <Row className="skill-info-form">
      <Form onSubmit={onSubmit}>
        <Row>
          <Col md="5" sm="5">
            <h6>&nbsp;</h6>
            <ImageUpload
              imageFile={_imageFile}
              onChange={(file) => {
                setImageFile(file);
              }}
            />
            <FormGroup>
              <h6 title="Must be https:// protocol">
                Video URL <span className="icon-danger">*</span>
              </h6>
              <Input
                className="border-input"
                placeholder="https://example.com/?video=abc123"
                type="text"
                /* eslint-disable-next-line max-len */
                pattern="https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
                onChange={(e) => setVideoURL(e.target.value)}
                value={_videoURL}
              />
            </FormGroup>
            <FormGroup className={isTest ? '' : 'invisible'}>
              <h6>
                Name<span className="icon-danger">*</span>
              </h6>
              <Input
                className="border-input"
                placeholder="name"
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={_name}
              />
            </FormGroup>
            <FormGroup className={isTest ? '' : 'invisible'}>
              <h6>
                Skill ID <span className="icon-danger">*</span>
              </h6>
              <Input
                className="border-input"
                placeholder="name"
                type="text"
                onChange={(e) => setSkillID(e.target.value)}
                value={_skillID}
              />
            </FormGroup>
          </Col>
          <Col md="7" sm="7">
            <FormGroup>
              <h6>Description</h6>
              <Input
                className="textarea-limited"
                maxLength="400"
                placeholder="Describe the skill"
                rows="3"
                type="textarea"
                onChange={(e) => setDescription(e.target.value)}
                value={_description}
              />
            </FormGroup>
            <Row>
              <Col>
                <h6>Category</h6>
                <div id="category-input">
                  <TagsInput
                    onlyUnique
                    addOnBlur
                    addOnPaste
                    tagProps={{
                      className: 'react-tagsinput-tag badge-info disabled',
                    }}
                    disabled
                    inputProps={{ placeholder: '' }}
                    value={_category}
                  />
                </div>
              </Col>
              <Col>
                <h6>Difficulty</h6>
                <div id="difficulty-input">
                  <TagsInput
                    onlyUnique
                    addOnBlur
                    addOnPaste
                    tagProps={{
                      className: 'react-tagsinput-tag badge-info disabled',
                    }}
                    disabled
                    inputProps={{ placeholder: '' }}
                    value={_difficulty}
                  />
                </div>
              </Col>
            </Row>

            <h6>Muscles Used</h6>
            <div id="musclesUsed-input">
              <TagsInput
                onlyUnique
                addOnBlur
                addOnPaste
                onChange={(value) => setMusclesUsed(value)}
                tagProps={{
                  className: 'react-tagsinput-tag badge-success',
                }}
                inputProps={{ placeholder: 'Add muscle' }}
                value={_musclesUsed}
              />
            </div>
            <h6>Related Injuries</h6>
            <div id="related-injuries-input">
              <TagsInput
                onlyUnique
                addOnBlur
                addOnPaste
                onChange={(value) => setRelatedInjuries(value)}
                tagProps={{
                  className: 'react-tagsinput-tag badge-danger',
                  placeholder: 'Add an injury',
                }}
                inputProps={{ placeholder: 'Add injury' }}
                value={_relatedInjuries}
              />
            </div>
            <h6>Other names</h6>
            <div id="other-names-input">
              <TagsInput
                placeholder="Add an alias"
                onlyUnique
                addOnBlur
                addOnPaste
                onChange={(value) => setOtherNames(value)}
                tagProps={{
                  className: 'react-tagsinput-tag badge-warning',
                }}
                inputProps={{ placeholder: 'Add alias' }}
                value={_otherNames}
              />
            </div>
          </Col>
        </Row>
        <Row className="buttons-row">
          <Col md="4" sm="4">
            <Button block className="btn-round" color="danger" outline type="reset">
              Cancel
            </Button>
          </Col>
          <Col md="4" sm="4">
            <Button block className="btn-round" color="primary" outline type="submit">
              Save
            </Button>
          </Col>
          <Col md="4" sm="4">
            <Button block className="btn-round" color="primary" type="submit">
              Save &amp; Publish
            </Button>
          </Col>
        </Row>
      </Form>
    </Row>
  );
}

export default SkillForm;
