/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Container, Row } from 'reactstrap';

// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer footer-white">
        <Container>
          <Row>
            <div className="credits ml-auto">
              <span className="copyright">
                © {new Date().getFullYear()}
                , Calisthenics for a year
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
