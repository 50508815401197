import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardTitle, Col, Container, Form, Input, Row } from 'reactstrap';
import LoadingMask from '../common/LoadingMask';
import { createMessage } from '../../actions/messages';
import { register } from '../../actions/auth';

function Register() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [loading, setLoading] = useState(false);
  const auth = useSelector((state) => state.auth);
  const errors = useSelector((state) => state.errors);
  const { isAuthenticated } = auth;
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== password2) {
      dispatch(createMessage({ passwordNotMatch: 'Passwords do not match' }));
    } else {
      const newUser = {
        username,
        password,
        email,
      };
      dispatch(register(newUser));
      setLoading(true);
      setUsername('');
      setEmail('');
      setPassword('');
      setPassword2('');
    }
  };

  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    setLoading(false);
    document.body.classList.add('register-page');
    document.body.classList.add('full-screen');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('register-page');
      document.body.classList.remove('full-screen');
    };
  }, [auth, errors]);
  if (isAuthenticated) {
    return <Redirect to="/" />;
  }
  return (
    <div className="wrapper register-page">
      <div
        className="page-header"
        style={{
          backgroundImage: `url(${  require('../../assets/img/sections/soroush-karimi.jpg')  })`,
        }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col className="ml-auto" lg="6" md="6" sm="7" xs="12">
              <div className="info info-horizontal">
                <div className="icon">
                  <i className="fa fa-umbrella" />
                </div>
                <div className="description">
                  <h3>We've got you covered</h3>
                  <p>
                    Larger, yet dramatically thinner. More powerful, but remarkably power efficient.
                    Everything you need in a single case.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon">
                  <i className="fa fa-map-signs" />
                </div>
                <div className="description">
                  <h3>Clear Directions</h3>
                  <p>
                    Efficiently unleash cross-media information without cross-media value. Quickly
                    maximize timely deliverables for real-time schemas.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon">
                  <i className="fa fa-user-secret" />
                </div>
                <div className="description">
                  <h3>We value your privacy</h3>
                  <p>
                    Completely synergize resource taxing relationships via premier niche markets.
                  </p>
                </div>
              </div>
            </Col>
            <Col className="mr-auto" lg="6" md="6" sm="5" xs="12">
              <LoadingMask loading={loading}>
                <Card className="card-register">
                  <CardTitle className="text-center" tag="h3">
                    Register
                  </CardTitle>
                  <div className="social">
                    <Button className="btn-just-icon mr-1" color="facebook">
                      <i className="fa fa-facebook" />
                    </Button>
                    <Button className="btn-just-icon mr-1" color="google">
                      <i className="fa fa-google" />
                    </Button>
                    <Button className="btn-just-icon" color="twitter">
                      <i className="fa fa-twitter" />
                    </Button>
                  </div>
                  <div className="division">
                    <div className="line l" />
                    <span>or</span>
                    <div className="line r" />
                  </div>
                  <Form className="register-form" onSubmit={onSubmit}>
                    <Input
                      placeholder="Username"
                      type="text"
                      onChange={(e) => setUsername(e.target.value)}
                      value={username}
                    />
                    <Input
                      placeholder="Email"
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <Input
                      placeholder="Password"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                    <Input
                      placeholder="Confirm Password"
                      type="password"
                      onChange={(e) => setPassword2(e.target.value)}
                      value={password2}
                    />
                    <Button block className="btn-round" color="default">
                      Register
                    </Button>
                  </Form>
                  <div className="login">
                    <p>
                      Already have an account? <Link to="/login"> Log in</Link>.
                    </p>
                  </div>
                </Card>
              </LoadingMask>
            </Col>
          </Row>
        </Container>
        <div className="demo-footer text-center">
          <h6>© {new Date().getFullYear()} - Calisthenics For A Year</h6>
        </div>
      </div>
    </div>
  );
}

export default Register;
