import React, { useState, useEffect } from 'react';
// reactstrap components
import { Button, FormGroup, Form, Input, Container, Row, Col } from 'reactstrap';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

// core components
import ColorNavbar from '../../components/Navbars/ColorNavbar';
import SettingsHeader from '../../components/Headers/SettingsHeader';
import FooterWhite from '../../components/Footers/FooterWhite';
import LoadingMask from '../../components/common/LoadingMask';
import { updateUser } from '../../actions/auth';

function Settings() {
  const user = useSelector((state) => _.get(state, 'auth.user'));
  const { id, firstName, lastName, email } = user;

  const [_firstName, setFirstName] = useState(firstName);
  const [_lastName, setLastName] = useState(lastName);
  const [_email, setEmail] = useState(email);
  const [loading, setLoading] = useState(false);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();

    const newUser = _.merge(user, {
      firstName: _firstName,
      lastName: _lastName,
      email: _email,
    });
    dispatch(updateUser(newUser));
    setLoading(true);
  };

  document.documentElement.classList.remove('nav-open');
  useEffect(() => {
    document.body.classList.add('settings-page');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('settings-page');
    };
  });

  useEffect(() => {
    setLoading(false);
  }, [errors, user]);
  return (
    <>
      <ColorNavbar />
      <div className="wrapper">
        <SettingsHeader />
        <div className="profile-content section">
          <Container>
            <Row>
              <div className="profile-picture">
                <div className="fileinput fileinput-new" data-provides="fileinput">
                  <div className="fileinput-new img-no-padding">
                    <img alt="..." src={require('../../assets/img/faces/clem-onojeghuo-2.jpg')} />
                  </div>
                  <div className="fileinput-preview fileinput-exists img-no-padding" />
                  <div>
                    <Button className="btn-file btn-round" color="default" outline>
                      <span className="fileinput-new">Change Photo</span>
                      <span className="fileinput-exists">Change</span>
                      <input name="..." type="file" />
                    </Button>
                    <br />
                    <Button
                      className="btn-link fileinput-exists"
                      color="danger"
                      data-dismiss="fileinput"
                      href="#pablo"
                    >
                      <i className="fa fa-times" />
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <LoadingMask loading={loading}>
                  <Form className="settings-form" onSubmit={onSubmit}>
                    <Row>
                      <Col md="6" sm="6">
                        <FormGroup>
                          <label>First Name</label>
                          <Input
                            className="border-input"
                            placeholder="First Name"
                            type="text"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={_firstName}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6" sm="6">
                        <FormGroup>
                          <label>Last Name</label>
                          <Input
                            className="border-input"
                            placeholder="Last Name"
                            type="text"
                            onChange={(e) => setLastName(e.target.value)}
                            value={_lastName}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <label>Email</label>
                      <Input
                        className="border-input"
                        placeholder="Email"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={_email}
                      />
                    </FormGroup>
                    <div className="text-center">
                      <Button className="btn-wd btn-round" color="info" type="submit">
                        Save
                      </Button>
                    </div>
                  </Form>

                </LoadingMask>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default Settings;
