import React from 'react';
import { Collapse, Container, Navbar, NavbarBrand } from 'reactstrap';
import Headroom from 'headroom.js';

import NavElement from './NavElement';

function Header() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [bodyClick, setBodyClick] = React.useState(false);
  React.useEffect(() => {
    const headroom = new Headroom(document.getElementById('navbar-main'));
    // initialise
    headroom.init();
  });
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="fixed-top" expand="lg" id="navbar-main">
        <Container>
          <NavbarBrand href="/">Calisthenics For A Year</NavbarBrand>
          <button
            className="navbar-toggler navbar-toggler-right"
            id="navbarSupportedContent3"
            type="button"
            onClick={() => {
              document.documentElement.classList.toggle('nav-open');
              setBodyClick(true);
              setCollapseOpen(true);
            }}
          >
            <span className="navbar-toggler-bar" />
            <span className="navbar-toggler-bar" />
            <span className="navbar-toggler-bar" />
          </button>
          <Collapse navbar isOpen={collapseOpen}>
            <NavElement />
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
