import {
  Badge,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import React from 'react';
import { logout } from '../../actions/auth';

function NavElement() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { isAuthenticated, user } = auth;

  const authLinks = (
    <>
      <NavItem>
        <NavLink href="/dashboard">Dashboard</NavLink>
      </NavItem>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle className="mr-2" color="default" caret nav>
          Components
        </DropdownToggle>
        <DropdownMenu className="dropdown-danger" right>
          <DropdownItem to="/index" tag={Link}>
            All Components
          </DropdownItem>
          <DropdownItem to="/presentation" tag={Link}>
            Presentation
          </DropdownItem>
          <DropdownItem
            href="https://demos.creative-tim.com/paper-kit-pro-react/#/documentation/introduction?ref=pkpr-danger-navbar"
            target="_blank"
          >
            Documentation
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle className="mr-2" color="default" caret nav>
          Sections
        </DropdownToggle>
        <DropdownMenu className="dropdown-danger" right>
          <DropdownItem to="/sections#headers" tag={Link}>
            <i className="nc-icon nc-tile-56" />
            Headers
          </DropdownItem>
          <DropdownItem to="/sections#features" tag={Link}>
            <i className="nc-icon nc-settings" />
            Features
          </DropdownItem>
          <DropdownItem to="/sections#blogs" tag={Link}>
            <i className="nc-icon nc-bullet-list-67" />
            Blogs
          </DropdownItem>
          <DropdownItem to="/sections#teams" tag={Link}>
            <i className="nc-icon nc-single-02" />
            Teams
          </DropdownItem>
          <DropdownItem to="/sections#projects" tag={Link}>
            <i className="nc-icon nc-calendar-60" />
            Projects
          </DropdownItem>
          <DropdownItem to="/sections#pricing" tag={Link}>
            <i className="nc-icon nc-money-coins" />
            Pricing
          </DropdownItem>
          <DropdownItem to="/sections#testimonials" tag={Link}>
            <i className="nc-icon nc-badge" />
            Testimonials
          </DropdownItem>
          <DropdownItem to="/sections#contact-us" tag={Link}>
            <i className="nc-icon nc-mobile" />
            Contact Us
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle color="default" caret nav>
          Examples
        </DropdownToggle>
        <DropdownMenu className="dropdown-danger" right>
          <DropdownItem to="/about-us" tag={Link}>
            <i className="nc-icon nc-bank" />
            About-us
          </DropdownItem>
          <DropdownItem to="/add-product" tag={Link}>
            <i className="nc-icon nc-basket" />
            Add Product
          </DropdownItem>
          <DropdownItem to="/blog-post" tag={Link}>
            <i className="nc-icon nc-badge" />
            Blog Post
          </DropdownItem>
          <DropdownItem to="/blog-posts" tag={Link}>
            <i className="nc-icon nc-bullet-list-67" />
            Blog Posts
          </DropdownItem>
          <DropdownItem to="/contact-us" tag={Link}>
            <i className="nc-icon nc-mobile" />
            Contact Us
          </DropdownItem>
          <DropdownItem to="/discover" tag={Link}>
            <i className="nc-icon nc-world-2" />
            Discover
          </DropdownItem>
          <DropdownItem to="/e-commerce" tag={Link}>
            <i className="nc-icon nc-send" />
            Ecommerce
          </DropdownItem>
          <DropdownItem to="/landing-page" tag={Link}>
            <i className="nc-icon nc-spaceship" />
            Landing Page
          </DropdownItem>
          <DropdownItem to="/login-page" tag={Link}>
            <i className="nc-icon nc-lock-circle-open" />
            Login Page
          </DropdownItem>
          <DropdownItem to="/product-page" tag={Link}>
            <i className="nc-icon nc-album-2" />
            Product Page
          </DropdownItem>
          <DropdownItem to="/profile-page" tag={Link}>
            <i className="nc-icon nc-single-02" />
            Profile Page
          </DropdownItem>
          <DropdownItem to="/register-page" tag={Link}>
            <i className="nc-icon nc-bookmark-2" />
            Register Page
          </DropdownItem>
          <DropdownItem to="/search-with-sidebar" tag={Link}>
            <i className="nc-icon nc-zoom-split" />
            Search Page
          </DropdownItem>
          <DropdownItem to="/settings" tag={Link}>
            <i className="nc-icon nc-settings-gear-65" />
            Settings Page
          </DropdownItem>
          <DropdownItem to="/twitter-redesign" tag={Link}>
            <i className="nc-icon nc-tie-bow" />
            Twitter
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle className="btn-just-icon" color="warning" data-toggle="dropdown">
          <i className="nc-icon nc-sound-wave" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-notification" tag="ul" right>
          <li className="no-notification">You're all clear!</li>
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle className="btn-just-icon" color="default" data-toggle="dropdown">
          <i className="nc-icon nc-email-85" />
          <Badge className="notification-bubble" color="info" pill>
            2
          </Badge>
        </DropdownToggle>
        <DropdownMenu className="dropdown-wide dropdown-notification" tag="ul" right>
          <DropdownItem header>You have 7 unread notifications</DropdownItem>
          <li>
            <ul className="dropdown-notification-list scroll-area">
              <a className="notification-item" href="#pablo" onClick={(e) => e.preventDefault()}>
                <div className="notification-text">
                  <Badge color="success" pill>
                    <i className="nc-icon nc-chat-33" />
                  </Badge>
                  <span className="message">
                    <b>Patrick</b>
                    mentioned you in a comment.
                  </span>
                  <br />
                  <span className="time">20min ago</span>
                  <Button className="btn-just-icon read-notification btn-round" color="default">
                    <i className="nc-icon nc-check-2" />
                  </Button>
                </div>
              </a>
              <a className="notification-item" href="#pablo" onClick={(e) => e.preventDefault()}>
                <div className="notification-text">
                  <Badge color="info" pill>
                    <i className="nc-icon nc-alert-circle-i" />
                  </Badge>
                  <span className="message">Our privacy policy changed!</span>
                  <br />
                  <span className="time">1day ago</span>
                </div>
              </a>
              <a className="notification-item" href="#pablo" onClick={(e) => e.preventDefault()}>
                <div className="notification-text">
                  <Badge color="warning" pill>
                    <i className="nc-icon nc-ambulance" />
                  </Badge>
                  <span className="message">Please confirm your email address.</span>
                  <br />
                  <span className="time">2days ago</span>
                </div>
              </a>
              <a className="notification-item" href="#pablo" onClick={(e) => e.preventDefault()}>
                <div className="notification-text">
                  <Badge color="primary" pill>
                    <i className="nc-icon nc-paper" />
                  </Badge>
                  <span className="message">Have you thought about marketing?</span>
                  <br />
                  <span className="time">3days ago</span>
                </div>
              </a>
            </ul>
          </li>
          {/* end scroll area */}
          <li className="dropdown-footer">
            <ul className="dropdown-footer-menu">
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Mark all as read
                </a>
              </li>
            </ul>
          </li>
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle data-toggle="dropdown" height="30" width="30" tag={NavLink}>
          <div className="profile-photo-small">
            <img
              alt="..."
              className="img-circle img-responsive img-no-padding"
              src={require('../../assets/img/faces/joe-gardner-2.jpg')}
            />
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-info" right>
          {/* TODO: <DropdownItem href="/profile-page">Profile</DropdownItem> */}
          <DropdownItem href="/settings">Settings</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => dispatch(logout(user))}>Sign out</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );

  const guestLinks = (
    <NavItem>
      <Button className="btn-round" color="success" href="/login">
        Login
      </Button>
    </NavItem>
  );

  return (
    <Nav className="ml-auto" navbar>
      {isAuthenticated ? authLinks : guestLinks}
    </Nav>
  );
}

export default NavElement;
